// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import Article from '../components/Article'
import Container from '../components/Container'
import MetaTag from '../components/MetaTag'

// Step 2: Define your component
const CompanyPage = () => {
  return (
    <Layout>
      <Article title="company" color="black">
        <Container>
          <div className='sm:pr-0 pr-4 md:text-2xl text-center'>
            <div className='container'>
              <div className='flex mb-12'>
                <div className='sm:w-2/5 w-1/4 text-right min-w-[8em] pr-8'>社名</div>
                <div className='sm:w-3/5 w-3/4 text-left lg:pl-8'>
                  <span className='xsm:inline-block'>ONESEL株式会社</span>
                  <span className='xsm:inline-block'>（英文表記：ONESEL Co., Ltd.）</span>
                </div>
              </div>
              <div className='flex mb-12'>
                <div className='sm:w-2/5 w-1/4 text-right min-w-[8em] pr-8'>設立</div>
                <div className='sm:w-3/5 w-3/4 text-left lg:pl-8'>2023年1月</div>
              </div>
              <div className='flex mb-12'>
                <div className='sm:w-2/5 w-1/4 text-right min-w-[8em] pr-8'>資本金</div>
                <div className='sm:w-3/5 w-3/4 text-left lg:pl-8'>11,010,000円 ( 2023年12月 現在 )</div>
              </div>
              <div className='flex mb-12'>
                <div className='sm:w-2/5 w-1/4 text-right min-w-[8em] pr-8'>所在地</div>
                <div className='sm:w-3/5 w-3/4 text-left lg:pl-8'>〒158-0092<br />東京都世田谷区野毛2丁目9番12号</div>
              </div>
              <div className='flex mb-12'>
                <div className='sm:w-2/5 w-1/4 text-right min-w-[8em] pr-8'>営業所</div>
                <div className='sm:w-3/5 w-3/4 text-left lg:pl-8'>〒157-0062<br />東京都世田谷区南烏山2-37-7-205</div>
              </div>
              <div className='flex mb-12'>
                <div className='sm:w-2/5 w-1/4 text-right min-w-[8em] pr-8'>代表番号</div>
                <div className='sm:w-3/5 w-3/4 text-left lg:pl-8'>03-6824-4466</div>
              </div>
              <div className='flex mb-12'>
                <div className='sm:w-2/5 w-1/4 text-right min-w-[8em] pr-8'>代表取締役</div>
                <div className='sm:w-3/5 w-3/4 text-left lg:pl-8'>譜久島 基</div>
              </div>
              <div className='flex mb-12'>
                <div className='sm:w-2/5 w-1/4 text-right min-w-[8em] pr-8'>事業内容</div>
                <ul className='sm:w-3/5 w-3/4 text-left lg:pl-8'>
                  <li>メディアプラットフォーム事業</li>
                  <li>出版事業</li>
                  <li>制作事業</li>
                  <li>業務委託事業</li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </Article>
    </Layout>
  )
}

export default CompanyPage

export function Head({ location }) {
  return (
    <MetaTag
      pathname={location.pathname}
      title='company | ONESEL'
    >
    </MetaTag>
  )
}